// ====================
// Tables
// ====================

.table {
  @include border(1px);
  background: $color-white;
  border-collapse: collapse;
  width: 100%;

  thead {
    tr {
      th {
        text-align: left;
        @include border(0 0 1px 0);
        padding: $spacer-xs $spacer-sm;
        white-space: nowrap;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        color: $color-asphalt;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        @include border(0 0 1px 0);
        padding: 1.5 * $spacer-sm $spacer-sm;
        font-size: $font-size-md;
        font-weight: $font-weight-reg;
      }
    }
  }

  &--toggle {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin: 0 0 0 $spacer-xs;
    }
  }
}
