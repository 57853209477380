// ====================
// Variables
// ====================

// Colors
$color-dow-jones: #02A3D5;

$color-white: white;
$color-cool: #F5F7F8;
$color-stainless: #DFE6E9;
$color-gray: #B2BEC3;
$color-asphalt: #636E72;
$color-ink: #2D3436;
$color-black: black;

$color-lava-90: #2E0D08;
$color-lava-80: #5B1A0F;
$color-lava-70: #892817;
$color-lava-60: #B6351E;
$color-lava-50: #E44226;
$color-lava-40: #E96851;
$color-lava-30: #EF8E7D;
$color-lava-20: #F4B3A8;
$color-lava-10: #FAD9D4;
$color-lava-05: #FCECE9;

$color-sunshine-90: #32270D;
$color-sunshine-80: #634E1B;
$color-sunshine-70: #957628;
$color-sunshine-60: #C69D36;
$color-sunshine-50: #F8C443;
$color-sunshine-40: #F9D069;
$color-sunshine-30: #FBDC8E;
$color-sunshine-20: #FCE7B4;
$color-sunshine-10: #FEF3D9;
$color-sunshine-05: #FEF9EC;

$color-emerald-90: #10290E;
$color-emerald-80: #20531B;
$color-emerald-70: #317C29;
$color-emerald-60: #41A636;
$color-emerald-50: #51CF44;
$color-emerald-40: #74D969;
$color-emerald-30: #97E28F;
$color-emerald-20: #B9ECB4;
$color-emerald-10: #DCF5DA;
$color-emerald-05: #EDFAEC;

$color-ice-90: #0D2A33;
$color-ice-80: #1A5366;
$color-ice-70: #277D99;
$color-ice-60: #33A7CC;
$color-ice-50: #40D0FF;
$color-ice-40: #66DAFF;
$color-ice-30: #8DE3FF;
$color-ice-20: #B3ECFF;
$color-ice-10: #D9F6FF;
$color-ice-05: #EBFAFF;

$color-blurple-90: #11112B;
$color-blurple-80: #232257;
$color-blurple-70: #343382;
$color-blurple-60: #4644AE;
$color-blurple-50: #5755D9; // primary
$color-blurple-40: #7977E1;
$color-blurple-30: #9A99E8;
$color-blurple-20: #BCBBF0;
$color-blurple-10: #DDDDF7;
$color-blurple-05: #EEEEFB;


// Spacers
$spacer-xs: 4px;
$spacer-sm: 8px;
$spacer-smd: 12px;
$spacer-md: 16px;
$spacer-mlg: 24px;
$spacer-lg: 32px;
$spacer-xl: 48px;


// Typography
$fontstack: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-sm: 0.8125rem; // 13px
$font-size-md: 1rem; // 16px
$font-size-lg: 1.125rem; // 18px
$font-size-xl: 1.3125rem; // 21px
$font-size-xxl: 1.75rem; // 28px

$font-size-lg-bp2: 1.3125rem; // 21px
$font-size-xl-bp2: 1.75rem; // 28px
$font-size-xxl-bp2: 2.375rem; // 38px

$line-height-sm: 1rem; // 16px
$line-height-md: 1.5rem; // 24px
$line-height-lg: 1.625rem; // 26px
$line-height-xl: 1.875rem; // 30px
$line-height-xxl: 2.5625rem; // 41px

$line-height-lg-bp2: 1.875rem; // 30px
$line-height-xl-bp2: 2.5625rem; // 41px
$line-height-xxl-bp2: 3.4375rem; // 55px

$font-weight-reg: 400;
$font-weight-med: 500;
$font-weight-bold: 700;
$font-weight-black: 900;


// Styling
$border-radius: 2px;
$border: 1px solid $color-stainless;


// Z-Index
$zindex-top: 9999; // things that must always supersede everything else on the page, like modals
$zindex-major: 100; // things that generally go over other content, like fixed ui elements
$zindex-minor: 1; // small things that just need to go over existing inline content, like tooltips


// UI Elements
$navbar-height: 48px;
$navbar-height-bp2: 60px;
$bottom-toolbar-height: 56px;
$sidebar-width: 260px;
$panel-width: 400px;
$btn-height: 40px;
