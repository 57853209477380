// ====================
// Search Page
// ====================

.search-page--content {
  flex-grow: 1;
  overflow-y: auto;
  padding: $spacer-mlg;

  .search-results-text {
    @extend .text-md;
    color: $color-asphalt;
  }

  .search-page--content-inner {
    max-width: 800px;
    min-width: 500px;
    margin: 0 auto;
  }

  .chart-preview {
    &--card {
      background: $color-white;
      @include border(1px);
      border-radius: $border-radius;
      padding: $spacer-sm;
      margin: 0 0 $spacer-sm;
      display: flex;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0);
      transition: box-shadow 400ms;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &--img {
      flex-basis: 160px;
      flex-shrink: 0;
      background-color: $color-white;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include border(1px);
    }

    &--no-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: 160px;
      flex-shrink: 0;
      background: $color-cool;
      font-size: $font-size-sm;
      color: $color-asphalt;
      @include border(1px);

      .icon {
        margin: 0 0 $spacer-sm;
      }
    }

    &--info {
      flex-grow: 1;
      position: relative;
      padding: $spacer-sm $spacer-sm $spacer-xs $spacer-md;

      .token {
        text-transform: capitalize;
        margin: 0;
      }
    }

    &--info-top-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > div {
        flex-grow: 1;
        padding: 0 $spacer-lg 0 0;
      }
    }

    &--info-slug {
      @extend .text-sm;
      font-weight: $font-weight-bold;
      color: $color-asphalt;
      text-transform: uppercase;
      flex-grow: 1;
    }

    &--info-headline {
      @extend .text-md;
      font-weight: $font-weight-bold;
    }

    &--info-details {
      @extend .text-sm;
      color: $color-asphalt;

      strong {
        font-weight: $font-weight-med;
      }
    }
  }
}

@keyframes icon-rotate {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes icon-rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-1turn);
  }
}

.loading-icon-container {
  padding: 2 * $spacer-xl;
  text-align: center;

  .icon {
    color: $color-gray;
    animation: icon-rotate 1.5s linear infinite;
  }
}

// Paginator
.paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $spacer-mlg 0 0 0;

  .pages {
    margin: 0 $spacer-md;

    .btn {
      margin: 0 $spacer-sm;
    }
  }
}

// Search Panel
.search-panel-trigger {
  background: $color-white;
  @include border(0 1px 0 0);
  padding: $spacer-mlg $spacer-md;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn--tertiary .icon {
    margin: 0;
  }
}

.search-panel {
  display: flex;
  flex-direction: column;
  background: $color-white;
  @include border(0 1px 0 0);
  width: $sidebar-width + $spacer-lg;
  flex-shrink: 0;

  &--scroll {
    flex-grow: 1;
    padding: $spacer-mlg $spacer-mlg $spacer-sm;
    overflow: auto;
  }

  &--buttons {
    flex-shrink: 0;
    padding: $spacer-smd $spacer-mlg;
    @include border(1px 0 0 0);

    .btn:first-of-type {
      margin: 0 0 $spacer-sm;
    }
  }
}