// ====================
// Text Panel
// ====================

.panel--content-text {
  .series-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .input--checkbox {
      margin: 12px $spacer-sm 0 0;
    }

    .input--text,
    .input--textarea {
      flex-basis: calc(100% - #{$spacer-md} - #{$spacer-sm});
      flex-shrink: 0;
      margin: 0 0 $spacer-sm;
    }
  }
}
