// ====================
// Chart Page
// ====================

.chart-render {
  flex-grow: 1;
  padding: $spacer-mlg $spacer-mlg 0;
  overflow-y: auto;
}


// CHART CONTAINER
.chart-container {
  position: relative;
  background: $color-white;
  @include border(1px);
  margin: 0 0 $spacer-mlg;
  border-radius: $border-radius;
  max-width: calc(100vw - #{$sidebar-width} - (2 * #{$spacer-mlg})); // view width - sidebar width - .chart-render padding

  &--top {
    padding: $spacer-md $spacer-mlg;
    @include border(0 0 1px);
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &--label {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $color-asphalt;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &--middle {
    @include border(0 0 1px);
    overflow-x: scroll;
    overflow-y: hidden;

    .render-outer {
      display: inline-block;
      padding: $spacer-mlg;
    }

    .render-inner {
      @include border(1px);
      box-sizing: content-box;
      position: relative;
    }

    .hide-chart {
      display: none;
    }

    .dj-chart-container,
    .dj-chart-inner {
      position: absolute;
      top: 0;
      left: 0;
    }

    .dj-chart-inner {
      overflow: visible !important;
    }
  }

  &--bottom {
    padding: $spacer-mlg;

    .btn-group {
      margin: 0 0 $spacer-md;
    }

    &--height-adjuster {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 $spacer-md;

      label {
        flex-basis: 100%;
        flex-shrink: 0;
      }

      .input--text {
        flex-basis: 120px;
        margin: 0 $spacer-md 0 0;
      }

      .input--range {
        flex-basis: 300px;
      }

      .btn-group {
        margin: 0;
      }
    }

    &--export-buttons {
      .btn + .btn {
        margin-left: $spacer-sm;
      }
    }
  }
}

#render-print-wsj .dj-chart-container.scaled {
  transform-origin: left top;
  transform: scale(1.5);
}


// BOTTOM TOOLBAR
.bottom-toolbar {
  flex-shrink: 0;
  background: $color-white;
  @include border(1px 0 0 0);
  display: flex;
  justify-content: flex-end;
  padding: $spacer-md $spacer-mlg;

  > div {
    display: flex;
    align-items: center;
  }

  label {
    display: inline-block;
    margin: 0;
  }

  .btn--tertiary {
    margin: 0 0 0 $spacer-md;
  }
}

// DATA MODAL
.modal-title {
  margin-bottom: 20px;
}

.modal-subtitle {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 5px;
  border: black 1px solid;
}