// ====================
// Annotations Panel
// ====================

.panel--content-annotations {
  .annotation-item {
    position: relative;
    margin: 0 0 $spacer-md;
    @include border(1px);
    border-radius: $border-radius;
    padding: $spacer-md;
  }

  .btn--remove-annotation {
    position: absolute;
    top: -1 * $spacer-sm;
    right: -1 * $spacer-sm;
    border: none;
    background: $color-ink;
    color: $color-white;
    width: $spacer-mlg;
    height: $spacer-mlg;
    box-shadow: none;
    border-radius: 50%;
    padding: $spacer-xs;
    cursor: pointer;

    &:hover {
      background: $color-black;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
