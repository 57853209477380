// ====================
// Color Panel
// ====================

.panel--content-color {

  // Theme List
  .color-theme {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 0 $spacer-sm;
    }

    &--btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;
      background: $color-white;
      @include border(1px);
      border-radius: $border-radius;
      box-shadow: none;
      padding: $spacer-xs $spacer-xs $spacer-xs $spacer-sm;
      font-size: $font-size-md;
      font-weight: $font-weight-med;
      cursor: pointer;

      &:hover {
        background: rgba($color-stainless, 0.5);
      }

      &--selected {
        background: $color-stainless;

        &:hover {
          background: rgba($color-gray, 0.5);
        }
      }

      &:focus {
        @include focus-state;
      }

      .color-sample-group {
        display: flex;
        border: 2px solid $color-white;

        .color-sample {
          display: block;
          width: $spacer-mlg;
          height: $spacer-mlg;
        }
      }
    }
  }

  // Series Color Group
  .color-options {
    .btn--color-sample {
      display: flex;
      align-items: center;
      background: $color-white;
      @include border(1px);
      border-radius: $border-radius;
      box-shadow: none;
      padding: $spacer-xs;
      margin: 0 $spacer-sm $spacer-sm 0;
      white-space: normal;
      cursor: pointer;

      &--label {
        font-size: $font-size-md;
        margin: 0 $spacer-sm;
        font-weight: $font-weight-reg
      }
      
      &:disabled{
        background: rgba($color-stainless, 0.5);
      }
      &:hover {
        background: rgba($color-stainless, 0.5);
      }

      &:focus {
        @include focus-state;
      }

      .color-sample {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        height: 20px;
        width: 44px;
        // width: 2 * $spacer-mlg;
        // height: $spacer-mlg;
        background-color: white;
        border: 2px solid $color-white;

        .above,
        .below {
          display: block;
          flex-grow: 1;
        }

      }
      .st_icon {
        margin: 0 2px 0 4px;
      }

      // .color-sample + .icon {
      //   margin: 0 0 0 $spacer-sm;
      // }
    }

    &--series {
      display: flex;
      flex-direction: row;

      button{
        margin: 0 $spacer-sm $spacer-sm 0;
      }

      &--threshold {
        padding-left: $spacer-mlg;
      }
    }

    &--line-types {
      position: absolute;
      left: 100px;
    }
    
    &--points {
      &--list {
        padding-left: $spacer-mlg;
        display: flex;
        flex-direction: column;
      }
    }

    &--color-picker {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--color-picker-wsj {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &--picker-row {
      display: flex;
    }

    .series-name {
      text-align: left;
      width: min-content;
      max-width: 296px;
      height: 48px;
      white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipse !important;
    }

    button.drop-down {
      i.icon {
        margin-right: 0.5rem;
      }
    }

    button.series-color {
      float: left;
      width: 20% !important;
      height: 48px;
    }

    p.warning {
      margin-bottom: 1rem !important;
    }
  }
  .color-container {
    position: relative
  }
}
