// ====================
// Tokens
// ====================

.token {
  display: inline-flex;
  align-items: center;
  position: relative;
  @extend .text-sm;
  padding: 1px $spacer-xs;
  margin: 0 $spacer-xs $spacer-xs 0;
  border-radius: $border-radius;
  white-space: nowrap;
  border: none; // overrides if used on a <button>
  box-shadow: none;

  &:focus {
    @include focus-state;
  }

  .icon:not(.token--dismiss) {
    margin: 0 $spacer-xs 0 0;
  }

  &--with-avatar {
    border-radius: 9px $border-radius $border-radius 9px;
  }

  &--avatar {
    width: $spacer-md + 2px;
    height: $spacer-md + 2px;
    background: $color-gray;
    border-radius: 50%;
    margin: -1px $spacer-xs -1px calc(-1 * #{$spacer-xs});

    &:after {
      content: "";
    }
  }

  &--dismiss {
    margin: 0 0 0 $spacer-xs;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &--blurple {
    color: $color-blurple-70;
    background: $color-blurple-10;

    .icon {
      fill: $color-blurple-70;
    }
  }

  &--gray {
    color: $color-ink;
    background: $color-stainless;

    .icon {
      fill: $color-ink;
    }
  }

  &--lava {
    color: $color-lava-70;
    background: $color-lava-10;

    .icon {
      fill: $color-lava-70;
    }
  }

  &--emerald {
    color: $color-emerald-70;
    background: $color-emerald-10;

    .icon {
      fill: $color-emerald-70;
    }
  }

  &--sunshine {
    color: $color-sunshine-70;
    background: $color-sunshine-10;

    .icon {
      fill: $color-sunshine-70;
    }
  }

  &--ice {
    color: $color-ice-70;
    background: $color-ice-10;

    .icon {
      fill: $color-ice-70;
    }
  }
}
