.st-label {
  //overwrites dafault 500 font-weight in FormLabel component
  //src/ui/components/chartview/panels/annotations/PlotLineDropDown.jsx
  .st_label__text {
    font-weight: 700;
  }
  .st_select {
    -webkit-appearance: none;
  }
}

//overwrites default md padding, flex, so span can be inline - Alert component
//src/ui/components/chartview/panels/document/PubGroups.jsx
.st-promo-message {
  padding: $spacer-sm;
  margin-bottom: $spacer-sm;

  .st_grp {
    display: inline-block;
    margin: 0;
    a {
      cursor: pointer;
    }
  }
}

.st_color-picker--btn--trigger {
  white-space: normal;
}

// temp solution to get invalid input working for Axis component
.input-btn-wrapper .st_input--invalid[type='text'] {
  border-color: $color-lava-50;
  background-color: $color-lava-05;
}
