// ====================
// Buttons
// ====================

.btn {
  display: inline-flex;
  align-items: center;
  position: relative;
  border: none;
  box-shadow: none;
  font-family: $fontstack;
  font-size: $font-size-md;
  font-weight: $font-weight-med;
  text-decoration: none;
  border-radius: $border-radius;
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms;

  .icon {
    margin: 0 $spacer-sm 0 0;
    font-size: $font-size-md;
    fill: $color-blurple-50;
  }

  // Primary
  &--primary {
    background: $color-blurple-50;
    color: $color-white;
    height: $btn-height;
    padding: 0 $spacer-lg;
    justify-content: center;

    .icon {
      fill: $color-white;
    }

    &:hover {
      background: $color-blurple-60;
    }

    &:active {
      background: $color-blurple-70;
    }
  }

  // Secondary
  &--secondary {
    background: $color-white;
    border: 1px solid $color-blurple-50;
    color: $color-blurple-50;
    height: $btn-height;
    padding: 0 $spacer-lg;
    justify-content: center;

    &:hover {
      background: $color-blurple-05;
    }

    &:active {
      background: $color-blurple-10;
    }
  }

  // Tertiary
  &--tertiary {
    height: $spacer-mlg;
    background: transparent;
    color: $color-blurple-50;
    padding: 0 $spacer-xs;
    margin: 0 -1 * $spacer-xs;
    background: transparent;
    border-radius: $border-radius;
    transition: all 300ms;

    &:hover {
      background: $color-blurple-05;
    }

    &:active {
      background: $color-blurple-10;
    }
  }

  // Icon on the right
  &--icon-right {
    flex-direction: row-reverse;

    .icon {
      margin: 0 0 0 $spacer-sm;
    }
  }

  // Full Width
  &--full-width {
    display: flex;
    width: 100%;
  }

  // Focus
  &:focus {
    @include focus-state;
  }

  // Disabled
  &--disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  // Floating Action Button
  &--fab {
    position: fixed;
    bottom: $spacer-lg;
    right: $spacer-lg;
    width: $spacer-xl + $spacer-sm;
    height: $spacer-xl + $spacer-sm;
    justify-content: center;
    background: transparent;
    box-shadow: 0 2px $spacer-md rgba($color-blurple-50, 0.5);
    border-radius: 50%;
    color: $color-white;

    .icon {
      fill: $color-white;
      margin: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $color-blurple-50;
      transform: scale(1, 1);
      transition: all 400ms;
      z-index: -1;
    }

    &:hover:before {
      background: $color-blurple-60;
      transform: scale(1.1, 1.1);
    }
  }
}

// Button Group
.btn-group {
  .btn {
    margin: 0 -1px 0 0;
    border-radius: 0;
    padding: 0 $spacer-md;

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &.selected {
      background: $color-blurple-50;
      color: white;
    }
  }
}
