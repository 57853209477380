// ====================
// Icons
// ====================

.icon {
  fill: currentColor;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: baseline;
  line-height: 1em;
}

$icon-sizes: (
  sm: $spacer-sm,
  smd: $spacer-smd,
  md: $spacer-md,
  mlg: $spacer-mlg,
  lg: $spacer-lg
);

@each $name, $value in $icon-sizes {
  .icon--#{$name} {
    width: $value;
    height: $value;
  }
}
