// ====================
// IMPORTS
// ====================

@import '@screentone/core/css/index.min.css';
@import '@screentone/addon-auth-wrapper/css/index.css';
@import '@screentone/addon-calendar/css/index.css';
@import '@screentone/addon-color-picker/css/index.css';

// CORE
@import 'styles/_variables.scss';
@import 'styles/_typography.scss';
@import 'styles/_icons.scss';
@import 'styles/_common.scss';

// ELEMENTS
// All of these styles should eventually come from Screentone
@import 'styles/_buttons.scss';
@import 'styles/_forms.scss';
@import 'styles/_tables.scss';
@import 'styles/_tokens.scss';

// COMPONENTS
// Styles for components specific to Chartlos
@import 'styles/_navbar.scss';
@import 'styles/_panels.scss';
@import 'styles/_login.scss';

// VIEWS
// TODO: decouple from initial paint
// Styles specific to views
@import 'styles/SearchPage.scss';
@import 'styles/ChartPage.scss';
@import 'styles/EditToolbar.scss';
@import 'styles/MarketDataPanel.scss';
@import 'styles/TextPanel.scss';
@import 'styles/PublishPanel.scss';
@import 'styles/ColorsPanel.scss';
@import 'styles/AnnotationsPanel.scss';
@import 'styles/HistoryPanel.scss';
//screentone classes overwritten
@import 'styles/stCustom.scss';

// ====================
// Base
// ====================

body,
html {
  background-color: $color-cool;
  font-family: $fontstack !important;
  font-size: 16px;
  color: $color-ink;
  -webkit-font-smoothing: antialiased;
  position: relative;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

#main {
  height: 100%;
  overflow-y: scroll;
}

.scrollable {
  overflow: auto;
  background-color: $color-cool;
}

.securityHeight {
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--st-headerbar-height-bp-1));

  &--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }
}

// Added to work around a bug where the
// FAB container is missing a z-index
.st_btn--fab-container {
  z-index: 1;
}

.flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}