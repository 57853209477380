// ====================
// Forms
// ====================

// Label
label {
  display: block;
  @extend .text-sm;
  font-weight: $font-weight-bold;
}

// This assumes <label><input type='checkbox'></input>Hello</label> markup
.label--checkbox-radio {
  margin: 0 0 1.5 * $spacer-sm;
  display: flex;
  align-items: center;
  font-size: $font-size-md;
  font-weight: $font-weight-reg;
  cursor: pointer;
}


// Common styles
.input--text,
.input--textarea,
.input--select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  @include border(1px);
  border-radius: $border-radius;
  background: $color-white;
  color: $color-ink;
  padding: $spacer-sm;
  margin: 0 0 $spacer-xs;
  line-height: $line-height-md;
  font-family: $fontstack;
  font-size: $font-size-md;
  box-shadow: none;

  &:focus {
    @include focus-state;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

::placeholder {
  color: $color-gray;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-gray;
}

::-ms-input-placeholder {
  color: $color-gray;
}

.input--no-margin {
  margin: 0;
}


// Text input
.input--text {
  height: $btn-height;
}


// Textarea
.input--textarea {
  height: 2 * $btn-height;
  resize: vertical;

  &--code {
    font-family: monospace;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
}

// Select input
.input--select {
  height: $btn-height;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgOCAxMCI+CiAgPHBhdGggZmlsbD0iIzYzNkU3MiIgZD0iTTIxLDI1IEwxNywyMSBMMjUsMjEgTDIxLDI1IFogTTIxLDE1IEwyNSwxOSBMMTcsMTkgTDIxLDE1IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNyAtMTUpIi8+Cjwvc3ZnPgo=');
  background-size: 8px 10px;
  background-position: calc(100% - #{$spacer-md}) center;
  background-repeat: no-repeat;
  cursor: pointer;
}

// Checkbox Inputs
.input--checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: $spacer-md;
  height: $spacer-md;
  position: relative;
  margin: 0 $spacer-xs 0 0;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: $spacer-md;
    height: $spacer-md;
    font-size: $font-size-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    @include border(1px);
    border-radius: $border-radius;
    background: $color-white;
    box-sizing: border-box;
  }

  &:checked::after {
    content: "✓";
    background: $color-blurple-50;
    border-color: $color-blurple-50;
    color: $color-white;
  }

  &:focus {
    @include focus-state;
  }
}


// Range input
// Got this from here: https://github.com/darlanrod/input-range-sass
// I'm aware this is a riduclous amount of code but hey at least the sliders match the style!
$thumb-height: $spacer-md !default;
$thumb-width: $spacer-md !default;

$track-color: $color-stainless;
$track-width: 100% !default;
$track-height: $spacer-xs !default;
$track-border-width: 1px !default;
$track-border-color: $color-stainless !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb($adjustment: 0) {
  background: $color-blurple-50;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  cursor: default;
  height: $thumb-height + $adjustment;
  width: $thumb-width + $adjustment;
}

@mixin disabled {
  cursor: not-allowed;
}

.input--range {
  -webkit-appearance: none;
  background: transparent;
  margin: $thumb-height / 2 0;
  width: $track-width;
  cursor: pointer;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($color-stainless, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($color-stainless, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb(-4);
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: $color-stainless;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb(-4);
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb { @include disabled; }
    &::-moz-range-thumb { @include disabled; }
    &::-ms-thumb { @include disabled; }
    &::-webkit-slider-runnable-track { @include disabled; }
    &::-ms-fill-lower { @include disabled; }
    &::-ms-fill-upper { @include disabled; }
  }
}


// Toggle input
.input--toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin: 0 0 $spacer-md;

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
    background: $color-gray;
    border-radius: $spacer-md - 1px;
    margin: 3px $spacer-sm 0 0;
    cursor: pointer;
    flex-shrink: 0;

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      display: block;
      width: 16px;
      height: 16px;
      background: $color-white;
      border-radius: 50%;
      transform: translate(0, 0);
      transition: all 250ms;
    }

    &:checked {
      background: $color-blurple-50;

      &::after {
        transform: translate(100%, 0);
      }
    }

    &:focus {
      @include focus-state;
    }
  }

  label {
    @extend .text-md;
    margin: 0;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.document {
    margin: 0 0 $spacer-xs;
  } 
}

.input--toggle + .input--toggle,  .input--toggle + .input--toggle-group {
  margin-top: -1 * $spacer-sm;
}


// Wrappers
// These feel a little dirty/non-foolproof, but rolling with it for now
.input-btn-wrapper {
  display: flex;
  align-items: center;

  input[type="text"] {
    @extend .input--text;
  }

  .input--text,
  .input--select,
  > div {
    flex-grow: 1;
    margin-right: $spacer-sm;
    @extend .input--no-margin;
  }
}

.input-input-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
    flex-shrink: 0;
  }

  > div {
    flex-grow: 1;

    &:not(:last-of-type) {
      margin-right: $spacer-sm;
    }
  }
}
