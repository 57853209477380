// ====================
// Common Styles
// ====================

@mixin border($border-width) {
  border: solid $color-stainless;
  border-width: $border-width;
}

@mixin focus-state() {
  border-color: $color-blurple-50;
  outline: none;
  box-shadow: 0 0 0 2px rgba($color-blurple-50, 0.5);
}

hr {
  background: $color-stainless;
  outline: none;
  height: 1px;
  width: 100%;
  border: none;
}


// Details Accordion
.details {
  &--summary {
    @extend .text-sm;
    font-weight: $font-weight-med;
    // display: flex;
    // align-items: center;
    cursor: pointer;

    &::marker {
      font-size: 75%;
      margin: 0 $spacer-sm 0 0;
    }

    &:focus {
      outline: none;
    }
  }

  &--text-panel {
    @extend .text-sm;
    font-weight: $font-weight-reg;
    // display: flex;
    // align-items: center;
    cursor: pointer;

    &::marker {
      font-size: 75%;
      margin: 0 $spacer-sm 0 0;
    }

    &:focus {
      outline: none;
    }
  }
  

  &--content {
    @include border(1px);
    background: $color-cool;
    border-radius: $border-radius;
    padding: $spacer-sm;
    margin-bottom: $spacer-sm;
  }
}

.required {
  margin: 0 0 0 0;

  &::after {
    content: ' *';
    color: $color-lava-60;
  }
}

// Padding & Margin Utilities
@mixin make-padding($size, $padding) {
  .p-hor-#{$size} {
    padding-left: $padding;
    padding-right: $padding;
  }

  .p-left-#{$size} {
    padding-left: $padding;
  }

  .p-right-#{$size} {
    padding-right: $padding;
  }

  .p-ver-#{$size} {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  .p-bot-#{$size} {
    padding-bottom: $padding;
  }

  .p-top-#{$size} {
    padding-top: $padding;
  }

  .p-#{$size} {
    padding: $padding;
  }
}

@mixin make-margin($size, $padding) {
  .m-hor-#{$size} {
    margin-left: $padding;
    margin-right: $padding;
  }

  .m-left-#{$size} {
    margin-left: $padding;
  }

  .m-right-#{$size} {
    margin-right: $padding;
  }

  .m-ver-#{$size} {
    margin-top: $padding;
    margin-bottom: $padding;
  }

  .m-bot-#{$size} {
    margin-bottom: $padding;
  }

  .m-top-#{$size} {
    margin-top: $padding;
  }

  .m-#{$size} {
    margin: $padding;
  }
}

@include make-padding(xs, $spacer-xs);
@include make-padding(sm, $spacer-sm);
@include make-padding(smd, $spacer-smd);
@include make-padding(md, $spacer-md);
@include make-padding(mlg, $spacer-mlg);
@include make-padding(lg, $spacer-lg);
@include make-padding(xl, $spacer-xl);
@include make-margin(xs, $spacer-xs);
@include make-margin(sm, $spacer-sm);
@include make-margin(smd, $spacer-smd);
@include make-margin(md, $spacer-md);
@include make-margin(mlg, $spacer-mlg);
@include make-margin(lg, $spacer-lg);
@include make-margin(xl, $spacer-xl);
