// ====================
// History Panel
// ====================

.panel--content-history {
  .history-item {
    position: relative;
    padding: 0 0 $spacer-lg $spacer-md;

    &:not(:last-of-type) {
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        bottom: -6px;
        width: 1px;
        background: $color-gray;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: -5px;
      width: 11px;
      height: 11px;
      border: 1px solid $color-gray;
      background: $color-white;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &--detail {
      @extend .text-md;
      margin: 0;
    }

    &--time {
      @extend .text-sm;
      color: $color-asphalt;
    }

    &--preview {
      width: 300px;
      border: $border;
      margin: $spacer-md auto 0 0;
    }
  }
}
