// ====================
// Panels
// ====================

.panels {
  position: fixed;
  top: $navbar-height-bp2;
  right: $sidebar-width;
  bottom: 2 * $spacer-md + $spacer-mlg + 1px; // height of .bottom-toolbar
  z-index: 2;
}

.panel {
  background: $color-white;
  border-left: solid 1px var(--st-border-color);
  height: 100%;
  overflow-x: visible;
  overflow-y: scroll;
  width: $panel-width;

  &--border-right {
    @include border(0 1px 0 0);
  }

  &--border-left {
    @include border(0 0 0 1px);
  }

  &--narrow {
    width: 260px;
  }

  &--wrapper {
    height: 100%;
    padding: $spacer-mlg $spacer-md 0;
  }

  &--header {
    align-items: center;
    margin: 0 0 $spacer-md;
    display: none; // TODO: this hides the entire PanelHeader component. Should we just remove that component??

    .icon {
      margin: 0 $spacer-sm 0 0;
    }

    h3 {
      margin: 0;
    }
  }

  &--row {
    margin: 0 0 $spacer-mlg;

    &--sm {
      margin: 0 0 $spacer-md;
    }
    &--top {
      margin-top: 10px
    }
  }

  hr:not(.st_divider) {
    margin: 0 0 $spacer-md;
  }

  &.closed {
    display: none;
  }
}
