// ====================
// Edit Toolbar
// ====================

#edit-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: $color-white;
  @include border(0 0 0 1px);
  padding: $spacer-mlg $spacer-xs;
  width: $sidebar-width;

  .edit-toolbar--btn {
    display: inline-flex;
    align-items: center;
    position: relative;
    border: none;
    box-shadow: none;
    font-size: $font-size-md;
    font-weight: $font-weight-med;
    width: 100%;
    height: $spacer-mlg + $spacer-xs;
    background: transparent;
    color: $color-ink;
    border-radius: $border-radius;
    padding: 0 $spacer-md;
    margin: 0;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: $color-blurple-05;
    }

    .icon {
      margin: 0 $spacer-sm 0 0;
    }

    &:focus {
      outline: none;
    }

    &--selected {
      color: $color-blurple-50;
      background: $color-blurple-05;

      &:hover {
        background: $color-blurple-05;
      }
    }

    &--publish {
      color: $color-blurple-50;
    }
  }
}