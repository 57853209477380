// ====================
// Market Data Panel
// ====================

.panel--content-market-data {
  .autocomplete {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 $spacer-sm;

      &:last-of-type {
        margin: 0 0 $spacer-md;
      }
    }
  }

  .more-list-trigger {
    .icon {
      margin: 0 0 0 $spacer-xs;
    }
  }

  .more-list {
    list-style-type: none;
    margin: $spacer-sm 0 0;
    padding: 0;

    li {
      margin: 0 0 $spacer-sm;
    }
  }

  .custom-inputs {
    display: flex;
    align-items: center;

    input[type="text"] {
      @extend .input--text;
      @extend .input--no-margin;
    }
  }

  .react-datepicker-wrapper {
    margin: 0 $spacer-sm 0 0;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .bls-form {
    margin-bottom: 20px;
  }
}



// ===== OLD =====

.no-end-date {
  text-align: center;
  color: $color-lava-50;
}
