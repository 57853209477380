// ====================
// Publish Panel
// ====================

.panel-publish {
  .inset-link {
    margin-left: 15px;
  }
}

.panel-publish {
  .link {
    text-decoration: underline;
  }
  .input--text{
    margin: 0 0 $spacer-sm;
  }
  .title-text{
    margin: $spacer-md 0 $spacer-sm;
    font-weight: 700;
  }

}
