// ====================
// Typography
// ====================

// Sizes & Margins
.text-sm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-reg;
  margin: 0 0 $spacer-xs;
    &.md-line-height{
      line-height: $line-height-md;
    }
}

.text-md {
  font-size: $font-size-md;
  line-height: $line-height-md;
  font-weight: $font-weight-reg;
  margin: 0 0 $spacer-sm;
}

.text-lg {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
  font-weight: $font-weight-med;
  margin: 0 0 $spacer-sm;
}

.text-xl {
  font-size: $font-size-xl;
  line-height: $line-height-xl;
  font-weight: $font-weight-med;
  margin: 0 0 $spacer-md;
}

.text-xxl {
  font-size: $font-size-xxl;
  line-height: $line-height-xxl;
  font-weight: $font-weight-bold;
  margin: 0 0 $spacer-md;
}

@media only screen and (min-width: 48em) {
  .text-lg {
    font-size: $font-size-lg-bp2;
    line-height: $line-height-lg-bp2;
  }

  .text-xl {
    font-size: $font-size-xl-bp2;
    line-height: $line-height-xl-bp2;
  }

  .text-xxl {
    font-size: $font-size-xxl-bp2;
    line-height: $line-height-xxl-bp2;
  }
}


p strong {
  font-weight: $font-weight-bold;
}

// Links
a, span {
  color: inherit;
  text-decoration: none;

  &.link {
    color: $color-blurple-50;
    font-weight: $font-weight-med;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.markdown {
  font-size: $font-size-sm;
}

.markdown a {
  color: inherit;
  text-decoration: none;
  color: $color-blurple-50;
  font-weight: $font-weight-med;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
}

// Code
code {
  background: $color-stainless;
  padding: 2px $spacer-xs;
  border-radius: $border-radius;
}
