// ====================
// Login
// ====================

.login {
  &--outer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &--container {
    background: $color-white;
    @include border(1px);
    border-radius: $border-radius;
    padding: $spacer-mlg;
    width: calc(100% - #{$spacer-xl});
    max-width: 340px;
    text-align: center;
  }

  &--image {
    max-width: 180px;
    margin: $spacer-mlg auto;
  }

  &--title {
    @extend .text-xl;
    margin: 0 0 $spacer-xs;
  }

  &--text {
    @extend .text-md;
    color: $color-asphalt;
    margin: 0 0 $spacer-mlg;
  }
}
