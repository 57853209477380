// ====================
// Navbar
// ====================

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: $color-white;
  @include border(0 0 1px 0);
  height: $navbar-height-bp2;
  min-height: $navbar-height-bp2;
  max-height: $navbar-height-bp2;
  width: 100%;
  padding: 0 $spacer-mlg;
  z-index: $zindex-major;

  // App Header
  &--app-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--app-title {
    @extend .text-lg;
    margin: 0;
  }

  &--view-all {
    color: $color-blurple-50;
    font-weight: $font-weight-bold;
    margin: 0 0 0 $spacer-sm;
    display: none;
  }

  // Chart Title
  &--chart-title-container {
    display: flex;
    align-items: center;

    .input--text {
      width: 260px;
      margin: 0;
      text-align: center;
    }
  }
}